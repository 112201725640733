module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PAUL-GAWMAN","short_name":"GAWMAN","start_url":"/","background_color":"#000000","theme_color":"#B7B7B7","display":"minimal-ui","icon":"src/img/favicon.png","icons":[{"src":"/favicon-144-144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon-192-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon-256-256.png","sizes":"256x256","type":"image/png"},{"src":"/favicon-384-384.png","sizes":"384x384","type":"image/png"},{"src":"/favicon-512-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e33143c9ccc507a80b7fbb629388792"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
